/*@tailwind base;*/

/**
 * Custom base styles
 */

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

html {
	font-size: 62.5%;
	font-family: Roboto, Helvetica Neue, Arial, sans-serif;
	background-color: #121212;
}

body {
	font-size: 14px;
	line-height: 1.4;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	margin: 0;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:active {
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

button:focus {
	outline: none;
}

@keyframes autofill {
	0%,100% {
		background: transparent;
	}
}
/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	animation-delay: 1s;
	animation-name: autofill!important;
	animation-fill-mode: both;
	-webkit-box-shadow:0 0 0 100px transparent inset!important;
}

:focus {
	outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
	height: 1px !important;
}


.col-gap-0 {
	grid-column-gap: 0;
	-webkit-column-gap: 0;
	column-gap: 0
}

.col-gap-2 {
	grid-column-gap: 0.2rem;
	-webkit-column-gap: 0.2rem;
	column-gap: 0.2rem
}

.col-gap-4 {
	grid-column-gap: 0.4rem;
	-webkit-column-gap: 0.4rem;
	column-gap: 0.4rem
}

.col-gap-6 {
	grid-column-gap: 0.6rem;
	-webkit-column-gap: 0.6rem;
	column-gap: 0.6rem
}

.col-gap-8 {
	grid-column-gap: 0.8rem;
	-webkit-column-gap: 0.8rem;
	column-gap: 0.8rem
}

.col-gap-10 {
	grid-column-gap: 1.0rem;
	-webkit-column-gap: 1.0rem;
	column-gap: 1.0rem
}

.col-gap-12 {
	grid-column-gap: 1.2rem;
	-webkit-column-gap: 1.2rem;
	column-gap: 1.2rem
}

.col-gap-16 {
	grid-column-gap: 1.6rem;
	-webkit-column-gap: 1.6rem;
	column-gap: 1.6rem
}

.col-gap-20 {
	grid-column-gap: 2rem;
	-webkit-column-gap: 2rem;
	column-gap: 2rem
}

.col-gap-24 {
	grid-column-gap: 2.4rem;
	-webkit-column-gap: 2.4rem;
	column-gap: 2.4rem
}

.col-gap-28 {
	grid-column-gap: 2.8rem;
	-webkit-column-gap: 2.8rem;
	column-gap: 2.8rem
}

.col-gap-32 {
	grid-column-gap: 3.2rem;
	-webkit-column-gap: 3.2rem;
	column-gap: 3.2rem
}

.col-gap-36 {
	grid-column-gap: 3.6rem;
	-webkit-column-gap: 3.6rem;
	column-gap: 3.6rem
}

.col-gap-40 {
	grid-column-gap: 4rem;
	-webkit-column-gap: 4rem;
	column-gap: 4rem
}

.col-gap-44 {
	grid-column-gap: 4.4rem;
	-webkit-column-gap: 4.4rem;
	column-gap: 4.4rem
}

.col-gap-48 {
	grid-column-gap: 4.8rem;
	-webkit-column-gap: 4.8rem;
	column-gap: 4.8rem
}

.col-gap-52 {
	grid-column-gap: 5.2rem;
	-webkit-column-gap: 5.2rem;
	column-gap: 5.2rem
}

.col-gap-56 {
	grid-column-gap: 5.6rem;
	-webkit-column-gap: 5.6rem;
	column-gap: 5.6rem
}

.col-gap-60 {
	grid-column-gap: 6rem;
	-webkit-column-gap: 6rem;
	column-gap: 6rem
}

.col-gap-64 {
	grid-column-gap: 6.4rem;
	-webkit-column-gap: 6.4rem;
	column-gap: 6.4rem
}

.col-gap-68 {
	grid-column-gap: 6.8rem;
	-webkit-column-gap: 6.8rem;
	column-gap: 6.8rem
}

.col-gap-72 {
	grid-column-gap: 7.2rem;
	-webkit-column-gap: 7.2rem;
	column-gap: 7.2rem
}

.col-gap-76 {
	grid-column-gap: 7.6rem;
	-webkit-column-gap: 7.6rem;
	column-gap: 7.6rem
}

.col-gap-80 {
	grid-column-gap: 8rem;
	-webkit-column-gap: 8rem;
	column-gap: 8rem
}

.col-gap-84 {
	grid-column-gap: 8.4rem;
	-webkit-column-gap: 8.4rem;
	column-gap: 8.4rem
}

.col-gap-88 {
	grid-column-gap: 8.8rem;
	-webkit-column-gap: 8.8rem;
	column-gap: 8.8rem
}

.col-gap-92 {
	grid-column-gap: 9.2rem;
	-webkit-column-gap: 9.2rem;
	column-gap: 9.2rem
}

.col-gap-96 {
	grid-column-gap: 9.6rem;
	-webkit-column-gap: 9.6rem;
	column-gap: 9.6rem
}

.col-gap-128 {
	grid-column-gap: 12.8rem;
	-webkit-column-gap: 12.8rem;
	column-gap: 12.8rem
}

.col-gap-136 {
	grid-column-gap: 13.6rem;
	-webkit-column-gap: 13.6rem;
	column-gap: 13.6rem
}

.col-gap-160 {
	grid-column-gap: 16rem;
	-webkit-column-gap: 16rem;
	column-gap: 16rem
}

.col-gap-192 {
	grid-column-gap: 19.2rem;
	-webkit-column-gap: 19.2rem;
	column-gap: 19.2rem
}

.col-gap-200 {
	grid-column-gap: 20rem;
	-webkit-column-gap: 20rem;
	column-gap: 20rem
}

.col-gap-208 {
	grid-column-gap: 20.8rem;
	-webkit-column-gap: 20.8rem;
	column-gap: 20.8rem
}

.col-gap-216 {
	grid-column-gap: 21.6rem;
	-webkit-column-gap: 21.6rem;
	column-gap: 21.6rem
}

.col-gap-224 {
	grid-column-gap: 22.4rem;
	-webkit-column-gap: 22.4rem;
	column-gap: 22.4rem
}

.col-gap-256 {
	grid-column-gap: 25.6rem;
	-webkit-column-gap: 25.6rem;
	column-gap: 25.6rem
}

.col-gap-288 {
	grid-column-gap: 28.8rem;
	-webkit-column-gap: 28.8rem;
	column-gap: 28.8rem
}

.col-gap-320 {
	grid-column-gap: 32rem;
	-webkit-column-gap: 32rem;
	column-gap: 32rem
}

.col-gap-360 {
	grid-column-gap: 36rem;
	-webkit-column-gap: 36rem;
	column-gap: 36rem
}

.col-gap-384 {
	grid-column-gap: 38.4rem;
	-webkit-column-gap: 38.4rem;
	column-gap: 38.4rem
}

.col-gap-400 {
	grid-column-gap: 40rem;
	-webkit-column-gap: 40rem;
	column-gap: 40rem
}

.col-gap-432 {
	grid-column-gap: 43.2rem;
	-webkit-column-gap: 43.2rem;
	column-gap: 43.2rem
}

.col-gap-512 {
	grid-column-gap: 51.2rem;
	-webkit-column-gap: 51.2rem;
	column-gap: 51.2rem
}

.col-gap-640 {
	grid-column-gap: 64rem;
	-webkit-column-gap: 64rem;
	column-gap: 64rem
}

.col-gap-xs {
	grid-column-gap: 32rem;
	-webkit-column-gap: 32rem;
	column-gap: 32rem
}

.col-gap-sm {
	grid-column-gap: 48rem;
	-webkit-column-gap: 48rem;
	column-gap: 48rem
}

.col-gap-md {
	grid-column-gap: 64rem;
	-webkit-column-gap: 64rem;
	column-gap: 64rem
}

.col-gap-lg {
	grid-column-gap: 80rem;
	-webkit-column-gap: 80rem;
	column-gap: 80rem
}

.col-gap-xl {
	grid-column-gap: 96rem;
	-webkit-column-gap: 96rem;
	column-gap: 96rem
}

.col-gap-2xl {
	grid-column-gap: 112rem;
	-webkit-column-gap: 112rem;
	column-gap: 112rem
}

.col-gap-3xl {
	grid-column-gap: 128rem;
	-webkit-column-gap: 128rem;
	column-gap: 128rem
}

.col-gap-4xl {
	grid-column-gap: 144rem;
	-webkit-column-gap: 144rem;
	column-gap: 144rem
}

.col-gap-5xl {
	grid-column-gap: 160rem;
	-webkit-column-gap: 160rem;
	column-gap: 160rem
}

.col-gap-px {
	grid-column-gap: 1px;
	-webkit-column-gap: 1px;
	column-gap: 1px
}

.row-gap-0 {
	grid-row-gap: 0;
	row-gap: 0
}

.row-gap-2 {
	grid-row-gap: 0.2rem;
	row-gap: 0.2rem
}

.row-gap-4 {
	grid-row-gap: 0.4rem;
	row-gap: 0.4rem
}

.row-gap-6 {
	grid-row-gap: 0.6rem;
	row-gap: 0.6rem
}

.row-gap-8 {
	grid-row-gap: 0.8rem;
	row-gap: 0.8rem
}

.row-gap-10 {
	grid-row-gap: 1.0rem;
	row-gap: 1.0rem
}

.row-gap-12 {
	grid-row-gap: 1.2rem;
	row-gap: 1.2rem
}

.row-gap-16 {
	grid-row-gap: 1.6rem;
	row-gap: 1.6rem
}

.row-gap-20 {
	grid-row-gap: 2rem;
	row-gap: 2rem
}

.row-gap-24 {
	grid-row-gap: 2.4rem;
	row-gap: 2.4rem
}

.row-gap-28 {
	grid-row-gap: 2.8rem;
	row-gap: 2.8rem
}

.row-gap-32 {
	grid-row-gap: 3.2rem;
	row-gap: 3.2rem
}

.row-gap-36 {
	grid-row-gap: 3.6rem;
	row-gap: 3.6rem
}

.row-gap-40 {
	grid-row-gap: 4rem;
	row-gap: 4rem
}

.row-gap-44 {
	grid-row-gap: 4.4rem;
	row-gap: 4.4rem
}

.row-gap-48 {
	grid-row-gap: 4.8rem;
	row-gap: 4.8rem
}

.row-gap-52 {
	grid-row-gap: 5.2rem;
	row-gap: 5.2rem
}

.row-gap-56 {
	grid-row-gap: 5.6rem;
	row-gap: 5.6rem
}

.row-gap-60 {
	grid-row-gap: 6rem;
	row-gap: 6rem
}

.row-gap-64 {
	grid-row-gap: 6.4rem;
	row-gap: 6.4rem
}

.row-gap-68 {
	grid-row-gap: 6.8rem;
	row-gap: 6.8rem
}

.row-gap-72 {
	grid-row-gap: 7.2rem;
	row-gap: 7.2rem
}

.row-gap-76 {
	grid-row-gap: 7.6rem;
	row-gap: 7.6rem
}

.row-gap-80 {
	grid-row-gap: 8rem;
	row-gap: 8rem
}

.row-gap-84 {
	grid-row-gap: 8.4rem;
	row-gap: 8.4rem
}

.row-gap-88 {
	grid-row-gap: 8.8rem;
	row-gap: 8.8rem
}

.row-gap-92 {
	grid-row-gap: 9.2rem;
	row-gap: 9.2rem
}

.row-gap-96 {
	grid-row-gap: 9.6rem;
	row-gap: 9.6rem
}

.row-gap-128 {
	grid-row-gap: 12.8rem;
	row-gap: 12.8rem
}

.row-gap-136 {
	grid-row-gap: 13.6rem;
	row-gap: 13.6rem
}

.row-gap-160 {
	grid-row-gap: 16rem;
	row-gap: 16rem
}

.row-gap-192 {
	grid-row-gap: 19.2rem;
	row-gap: 19.2rem
}

.row-gap-200 {
	grid-row-gap: 20rem;
	row-gap: 20rem
}

.row-gap-208 {
	grid-row-gap: 20.8rem;
	row-gap: 20.8rem
}

.row-gap-216 {
	grid-row-gap: 21.6rem;
	row-gap: 21.6rem
}

.row-gap-224 {
	grid-row-gap: 22.4rem;
	row-gap: 22.4rem
}

.row-gap-256 {
	grid-row-gap: 25.6rem;
	row-gap: 25.6rem
}

.row-gap-288 {
	grid-row-gap: 28.8rem;
	row-gap: 28.8rem
}

.row-gap-320 {
	grid-row-gap: 32rem;
	row-gap: 32rem
}

.row-gap-360 {
	grid-row-gap: 36rem;
	row-gap: 36rem
}

.row-gap-384 {
	grid-row-gap: 38.4rem;
	row-gap: 38.4rem
}

.row-gap-400 {
	grid-row-gap: 40rem;
	row-gap: 40rem
}

.row-gap-432 {
	grid-row-gap: 43.2rem;
	row-gap: 43.2rem
}

.row-gap-512 {
	grid-row-gap: 51.2rem;
	row-gap: 51.2rem
}

.row-gap-640 {
	grid-row-gap: 64rem;
	row-gap: 64rem
}

.row-gap-xs {
	grid-row-gap: 32rem;
	row-gap: 32rem
}

.row-gap-sm {
	grid-row-gap: 48rem;
	row-gap: 48rem
}

.row-gap-md {
	grid-row-gap: 64rem;
	row-gap: 64rem
}

.row-gap-lg {
	grid-row-gap: 80rem;
	row-gap: 80rem
}

.row-gap-xl {
	grid-row-gap: 96rem;
	row-gap: 96rem
}

.row-gap-2xl {
	grid-row-gap: 112rem;
	row-gap: 112rem
}

.row-gap-3xl {
	grid-row-gap: 128rem;
	row-gap: 128rem
}

.row-gap-4xl {
	grid-row-gap: 144rem;
	row-gap: 144rem
}

.row-gap-5xl {
	grid-row-gap: 160rem;
	row-gap: 160rem
}

.row-gap-px {
	grid-row-gap: 1px;
	row-gap: 1px
}
